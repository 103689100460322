@use "style-guide-typography" as *;
@use "style-guide-colors" as *;

@import "./fonts/Manrope";
@import "icons";
@import "reset";
@import "app-material-design";
@import "scrollbar";

html {
    height: 100vh;
    width: 100vw;
}

body {
    @include body-small-short;
    height: 100vh;
    width: 100vw;
    background-color: $color-gray-50;
    font-family: $typography-font-family;
    color: $color-gray-900;
}

button {
    font-family: $typography-font-family;
}

@import "components";

.capitalize {
    text-transform: capitalize;
}
