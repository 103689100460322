@use "../style-guide-colors" as *;
@use "../style-guide-typography" as *;
@use "../mixins" as *;

.mat-mdc-tooltip {
    --mdc-plain-tooltip-container-color: #{$color-white};
    --mdc-plain-tooltip-supporting-text-color: #{$color-gray-700};
    --mdc-plain-tooltip-supporting-text-size: #{$typography-size-body-description};
    --mdc-plain-tooltip-supporting-text-weight: #{$typography-weight-normal};

    @include toast-box-shadow;
    border-radius: 4px;

    .mdc-tooltip__surface {
        padding: 12px;
        line-height: $typography-line-height-body-description;
    }
}
