@use "libs/shared/styles/style-guide-colors" as *;
@use "libs/shared/styles/style-guide-typography" as *;
@use "libs/shared/styles/mixins" as *;

@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";

quill-editor:not(.error) {
    .ql-toolbar.ql-snow {
        border-color: $color-gray-100;
        border-radius: 8px 8px 0 0;
    }

    .ql-container {
        border-color: $color-gray-100;
    }

    .ql-editor {
        &:focus,
        &:focus-visible {
            @include focus-outline;
            border-radius: 0 0 8px 8px;
        }
    }
}

quill-editor.error {
    .ql-toolbar.ql-snow {
        border-color: $color-status-error;
        border-radius: 8px 8px 0 0;
    }

    .ql-container {
        border-color: $color-status-error;
    }
}

.ql-container.ngx-quill-view-html .ql-editor {
    white-space: normal;
}

.ql-container {
    .ql-editor {
        font-size: $typography-size-body-small-long;
        font-family: $typography-font-family;

        p:not(:first-of-type) {
            margin: 16px 0;
        }
    }

    .ql-editor .ql-size-small {
        font-size: $typography-size-body-description;
    }

    .ql-editor .ql-size-large {
        font-size: $typography-size-heading-4;
    }

    .ql-editor .ql-size-huge {
        font-size: $typography-size-heading-1;
    }

    width: var(--quillContainerWidth);
    height: var(--quillContainerHeight);
    border-radius: 0 0 8px 8px;
    overflow: hidden;

    .ql-editor {
        width: var(--quillContainerWidth);
        height: var(--quillEditorHeight);
    }
}

.ql-toolbar.ql-snow {
    display: block;

    .ql-size {
        width: 130px;
    }

    button:focus-visible {
        @include focus-outline(0);
        border-radius: 4px;
    }

    .ql-picker:not(.ql-expanded):focus-within .ql-picker-label {
        @include focus-outline(0);
        border-radius: 4px;
        color: $color-secondary-700;

        .ql-stroke {
            stroke: $color-secondary-700;
        }
    }

    .ql-picker.ql-expanded .ql-picker-label {
        outline: none;
        border: none;
    }

    .ql-picker-item:focus-within {
        @include focus-outline(0);
        border-radius: 4px;
        color: $color-secondary-700;
    }

    .ql-picker-options {
        padding: 0;

        .ql-picker-item:focus-within {
            @include focus-outline(0);

            .ql-stroke {
                stroke: $color-secondary-700;
            }
        }
    }
}

.ql-formats {
    position: relative;
}

.ql-custom-dropdown {
    display: none;
    position: absolute;
    width: 100px;
    left: 100%;
    top: 20px;
    flex-direction: column;
    background-color: $color-white;
    z-index: 2;
    border-radius: 8px;
    padding: 4px 8px;
    box-shadow: 0 8px 16px rgba($color-gray-500, 0.12);

    button[type="button"] {
        width: auto;
        text-align: left;
        padding: 4px;
        height: unset;

        &:focus-visible {
            @include focus-outline(0);
        }
    }
}

.ql-snow.ql-toolbar .box-button {
    width: auto;
}

.editor-joined-view {
    border: 1px solid $color-gray-100;
    border-radius: 8px;

    .ql-container.ql-snow,
    .ql-toolbar.ql-snow {
        border: 0;
        border-bottom: 1px solid $color-gray-50;
        border-radius: 0;
    }

    quill-editor.error {
        .ql-container.ql-snow {
            border: 1px solid $color-status-error;
        }
    }

    quill-editor:not(.error) {
        .ql-editor {
            &:focus,
            &:focus-visible {
                border: 1px solid $color-secondary-700;
                border-radius: 0;
            }
        }
    }
}
