// gray colors
$color-gray-900: #061636;
$color-gray-800: #0d254d;
$color-gray-700: #142e59;
$color-gray-600: #1c3664;
$color-gray-500: #223d6b;
$color-gray-400: #43577e;
$color-gray-300: #637292;
$color-gray-200: #8d99b1;
$color-gray-100: #b9c1d0;
$color-gray-50: #e3e6ec;
$color-white: #fff;

// primary
$color-primary-900: #ff6f00;
$color-primary-800: #ff8f00;
$color-primary-700: #ffa000;
$color-primary-600: #ffb300;
$color-primary-500: #ffc107;
$color-primary-400: #ffca28;
$color-primary-300: #ffd54f;
$color-primary-200: #ffe082;
$color-primary-100: #ffecb3;
$color-primary-50: #fff8e1;

// secondary
$color-secondary-900: #0849a4;
$color-secondary-800: #1067c3;
$color-secondary-700: #1477d5;
$color-secondary-600: #1d88ee;
$color-secondary-500: #1d97fc;
$color-secondary-400: #3fa6fd;
$color-secondary-300: #63b6fd;
$color-secondary-200: #90cbfe;
$color-secondary-100: #bbdffe;
$color-secondary-50: #e3f2fe;

// error
$color-error-900: #860020;
$color-error-800: #860020;
$color-error-700: #860020;
$color-error-600: #860020;
$color-error-500: #9b0020;
$color-error-400: #b00020;
$color-error-300: #c84c62;
$color-error-200: #df99a6;
$color-error-100: #e7b3bc;
$color-error-50: #f7e6e9;

// warning
$color-warning: #f56a00;

// red dark
$color-red-dark: #8e001a;

// contrast colors
$color-light-contrast: $color-white; // gray 900-300, secondary 900-700
$color-dark-contrast: $color-gray-700;

// status
$color-status-success: #007544;
$color-status-success-on-dark: #0f985c;
$color-status-success-background: #d6f2e7;
$color-status-success-background-hover: #bfe3cf;
$color-status-warning: $color-warning;
// TODO: DTM-5139 - update warning color names and usage
$color-status-warning-text: #cc3f12;
$color-status-error: $color-error-400;
$color-status-error-background: #f2d6db;
$color-status-error-background-hover: #fbcad2;
$color-status-link: $color-secondary-700;
$color-status-stop: #7629ff;
$color-status-stop-secondary: #c8a9ff;
$color-status-link-on-dark: $color-secondary-600;

// typography
$color-typography-light-heading: $color-gray-900;
$color-typography-light-body: $color-gray-700;
$color-typography-light-body-disabled: rgba($color-gray-700, 0.5);
$color-typography-light-label: $color-gray-300;
$color-typography-light-label-disabled: rgba($color-gray-300, 0.5);
$color-typography-dark-heading: $color-gray-50;
$color-typography-dark-label: $color-gray-200;
$color-typography-dark-label-disabled: $color-gray-400;

// pallets
$colors-palette-primary: (
    50: $color-primary-50,
    100: $color-primary-100,
    200: $color-primary-200,
    300: $color-primary-300,
    400: $color-primary-400,
    500: $color-primary-500,
    600: $color-primary-600,
    700: $color-primary-700,
    800: $color-primary-800,
    900: $color-primary-900,
    contrast: (
        50: $color-dark-contrast,
        100: $color-dark-contrast,
        200: $color-dark-contrast,
        300: $color-dark-contrast,
        400: $color-dark-contrast,
        500: $color-dark-contrast,
        600: $color-dark-contrast,
        700: $color-dark-contrast,
        800: $color-dark-contrast,
        900: $color-dark-contrast,
    ),
);

$colors-palette-secondary: (
    50: $color-secondary-50,
    100: $color-secondary-100,
    200: $color-secondary-200,
    300: $color-secondary-300,
    400: $color-secondary-400,
    500: $color-secondary-500,
    600: $color-secondary-600,
    700: $color-secondary-700,
    800: $color-secondary-800,
    900: $color-secondary-900,
    contrast: (
        50: $color-dark-contrast,
        100: $color-dark-contrast,
        200: $color-dark-contrast,
        300: $color-dark-contrast,
        400: $color-dark-contrast,
        500: $color-dark-contrast,
        600: $color-dark-contrast,
        700: $color-light-contrast,
        800: $color-light-contrast,
        900: $color-light-contrast,
    ),
);

$colors-palette-gray: (
    50: $color-gray-50,
    100: $color-gray-100,
    200: $color-gray-200,
    300: $color-gray-300,
    400: $color-gray-400,
    500: $color-gray-500,
    600: $color-gray-600,
    700: $color-gray-700,
    800: $color-gray-800,
    900: $color-gray-900,
    contrast: (
        50: $color-dark-contrast,
        100: $color-dark-contrast,
        200: $color-dark-contrast,
        300: $color-light-contrast,
        400: $color-light-contrast,
        500: $color-light-contrast,
        600: $color-light-contrast,
        700: $color-light-contrast,
        800: $color-light-contrast,
        900: $color-light-contrast,
    ),
);

$colors-palette-error: (
    50: $color-error-50,
    100: $color-error-100,
    200: $color-error-200,
    300: $color-error-300,
    400: $color-error-400,
    500: $color-error-500,
    600: $color-error-600,
    700: $color-error-700,
    800: $color-error-800,
    900: $color-error-900,
    contrast: (
        50: $color-dark-contrast,
        100: $color-dark-contrast,
        200: $color-dark-contrast,
        300: $color-light-contrast,
        400: $color-light-contrast,
        500: $color-light-contrast,
        600: $color-light-contrast,
        700: $color-light-contrast,
        800: $color-light-contrast,
        900: $color-light-contrast,
    ),
);
