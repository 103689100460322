@use "libs/shared/styles/style-guide-colors" as *;
@use "libs/shared/styles/style-guide-typography" as *;

.selectable-tile {
    border: 1px solid $color-gray-200;
    border-radius: 8px;
    padding: var(--selectable-tile-padding, 16px 20px);
    cursor: pointer;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .selectable-tile-icon {
        display: block;

        dtm-ui-icon {
            font-size: 1.5rem;
        }
    }

    .selectable-tile-wrapper {
        .selectable-tile-label {
            @include heading-6;
            margin-bottom: 8px;
        }

        .selectable-tile-description {
            @include body-description;
            font-weight: $typography-weight-normal;
            display: block;
            color: $color-gray-300;
            margin: 8px 0;
        }
    }

    &.horizontal {
        flex-direction: row;

        .selectable-tile-icon {
            justify-self: start;
        }

        .selectable-tile-wrapper {
            text-align: left;
        }
    }

    &.selected {
        border-width: 2px;
        border-color: $color-status-link;
        padding: var(--selectable-tile-selected-padding, 15px 19px);
        box-shadow: 0 6px 10px rgba($color-status-link, 0.14), 0 1px 18px rgba($color-status-link, 0.12),
            0 3px 5px rgba($color-status-link, 0.2);

        &.disabled,
        .disabled & {
            box-shadow: 0 6px 10px rgba($color-gray-200, 0.14), 0 1px 18px rgba($color-gray-200, 0.12), 0 3px 5px rgba($color-gray-200, 0.2);
            border-width: 2px;
        }
    }

    &:hover,
    &.hovered {
        border-color: $color-status-link;
        background-color: $color-secondary-50;
        box-shadow: 0 1px 18px rgba($color-secondary-600, 0.12);
    }

    &.disabled,
    .disabled & {
        opacity: 0.6;
        pointer-events: none;
        box-shadow: none;
        border: 1px solid $color-gray-200;
    }
}
