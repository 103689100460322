@use "../style-guide-colors" as *;
@use "../style-guide-typography" as *;
@use "../mixins" as *;

%button {
    font-size: var(--button-font-size, $typography-size-body-small-short);
    line-height: var(--button-line-height, $typography-line-height-body-small-short);
    font-weight: $typography-weight-bold;
    font-family: inherit;
    padding: 10px 24px;
    border-radius: 4px;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 4px;

    &[disabled] {
        opacity: 0.5;
        cursor: default;
    }

    &:focus-visible {
        @include focus-outline;
    }

    dtm-ui-icon {
        font-weight: $typography-weight-normal;
    }
}

.button-primary {
    @extend %button;
    background-color: $color-primary-400;
    color: $color-gray-700;

    &:hover:not([disabled]) {
        background-color: $color-primary-600;
    }

    .mat-spinner circle {
        stroke: $color-gray-700;
    }

    &-small {
        @extend .button-primary;
        padding: 6px 16px;
    }
}

.button-secondary {
    @extend %button;
    color: $color-secondary-700;
    padding-left: 16px;
    padding-right: 16px;

    &:hover:not([disabled]) {
        background-color: $color-secondary-50;
    }

    .mat-spinner circle {
        stroke: $color-secondary-700;
    }

    &-small {
        @extend .button-secondary;
        padding: 6px 16px;
    }
}

.button-link {
    @extend %button;
    color: $color-secondary-700;
    padding-inline: 4px;
    text-decoration: underline;
    font-weight: $typography-weight-normal;

    &:hover:not([disabled]) {
        background-color: $color-secondary-50;
    }

    &-small {
        @extend .button-link;
        padding: 0 2px;

        &:focus-visible {
            @include focus-outline(0);
        }
    }
}

.button-tertiary {
    @extend %button;
    color: $color-gray-300;
    padding-left: 16px;
    padding-right: 16px;
    text-decoration: underline;

    &:hover:not([disabled]) {
        color: $color-gray-700;
    }

    .mat-spinner circle {
        stroke: $color-gray-300;
    }

    &-small {
        @extend .button-tertiary;
        padding: 6px 16px;
    }

    &:has(> dtm-ui-icon) {
        text-decoration: none;

        > :not(dtm-ui-icon) {
            text-decoration: underline;
        }
    }
}

.button-warn {
    @extend %button;
    background: $color-error-400;
    color: $color-white;

    &:hover:not([disabled]) {
        background-color: $color-red-dark;
    }

    .mat-spinner circle {
        stroke: $color-gray-300;
    }

    &-small {
        @extend .button-warn;
        padding: 6px 16px;
    }
}

.button-icon {
    @extend .button-secondary;
    border-radius: 50%;
    padding: 10px;
    line-height: 1;
    font-size: 1.25rem;
    font-weight: $typography-weight-normal;

    &-small {
        @extend .button-icon;
        padding: 6px;
    }

    &-xsmall {
        @extend .button-icon;
        padding: 2px;
    }
}
