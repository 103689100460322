@use "../style-guide-colors" as *;
@use "../style-guide-typography" as *;

.chip {
    display: flex;
    align-items: center;
    padding: 4px 12px;
    gap: 4px;
    border-radius: 32px;
    width: fit-content;
    border: 1px solid;
    word-break: normal;
    @include body-description;

    dtm-ui-icon {
        margin-left: -4px;
    }

    &.error {
        background-color: $color-status-error-background;
        color: $color-status-error;
    }

    &.warning {
        background-color: $color-primary-200;
        color: $color-status-warning-text;
    }

    &.success {
        background-color: $color-status-success-background;
        color: $color-status-success;
    }

    &.explicit {
        background-color: $color-gray-500;
        color: $color-white;
        border-color: $color-gray-900;
    }

    &.disabled {
        background-color: $color-gray-100;
        color: $color-gray-700;
    }

    &.light {
        background-color: $color-gray-50;
        color: $color-gray-700;
    }

    &.primary {
        background-color: $color-primary-400;
        color: $color-gray-700;
    }

    &.secondary {
        background-color: $color-secondary-100;
        color: $color-secondary-900;
    }

    &.tertiary {
        background-color: $color-secondary-50;
        color: $color-secondary-900;
    }

    &.removable {
        background-color: $color-gray-50;
        color: $color-gray-700;

        dtm-ui-icon {
            color: $color-gray-500;

            &:hover {
                color: $color-gray-300;
            }
        }
    }
}
