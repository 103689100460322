@use "libs/shared/styles/style-guide-colors" as *;
@use "libs/shared/styles/mixins" as *;

@include scrollbar-colors($is-root: true);

.mat-dialog-content,
.scroll-shadows {
    background: linear-gradient(var(--scroll-shadows-background-color, white) 100%, rgba(255, 255, 255, 0)) center top,
        linear-gradient(rgba(255, 255, 255, 0), var(--scroll-shadows-background-color, white) 100%) center bottom,
        radial-gradient(farthest-side at 50% 0, rgba(34, 61, 107, 0.08), rgba(0, 0, 0, 0)) center top,
        radial-gradient(farthest-side at 50% 100%, rgba(34, 61, 107, 0.08), rgba(0, 0, 0, 0)) center bottom;
    background-repeat: no-repeat;
    background-size: 179% 15px, 163% 40px, 670% 15px, 223% 15px;
    background-attachment: local, local, scroll, scroll;

    &::-webkit-scrollbar-track {
        background: radial-gradient(var(--scroll-shadows-background-color, white), transparent);
    }
}
