@use "style-guide-typography" as *;
@use "style-guide-colors" as *;
@use "mixins" as *;

@import "components/buttons";
@import "components/grid";
@import "components/mat-input";
@import "components/chips";
@import "components/dialog";
@import "components/tiles";
@import "components/quill";
@import "components/table";
@import "components/mat-menu";
@import "components/mat-tabs";
@import "components/ngx-slider";
@import "components/mat-chips";
@import "components/mat-toggle";
@import "components/mat-spinner";
@import "components/mat-autocomplete";
@import "components/mat-tooltip";
@import "components/zoom-image";

.card-box,
mat-card.mat-card {
    &:not([class*="mat-elevation-z"]) {
        border-radius: 8px;
        box-shadow: 3px 6px 16px rgba($color-gray-500, 0.09);
        color: $color-gray-900;
        background-color: $color-white;
    }
    &.mat-elevation-z1 {
        box-shadow: none;
        border-radius: 6px;
        border: 1px solid $color-gray-100;
        padding: 20px;
    }
}

.mat-input-element::placeholder,
.mat-date-range-input-inner::placeholder {
    color: $color-gray-300;
}

input.mat-input-element,
textarea.mat-input-element {
    caret-color: $color-gray-900;
    margin-top: 0;
}

mat-expansion-panel.mat-expansion-panel:not([class*="mat-elevation-z"]) {
    box-shadow: 0 8px 16px rgba($color-gray-500, 0.12);
    border-radius: 8px;

    &:not(.mat-expanded),
    &:not(.mat-expansion-panel-spacing),
    &:not(.mat-expansion-panel-seperated) {
        border-radius: 0;
    }

    &:first-of-type {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
    }

    &:last-of-type {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    .mat-expansion-panel-header-title {
        color: $color-gray-900;
        font-size: 1rem;
        font-weight: 700;
    }

    &.mat-expansion-panel-seperated {
        border-radius: 8px;
        margin-bottom: 24px;
    }
}

.mat-expansion-indicator::after {
    color: $color-secondary-600;
}

mat-dialog-container.mat-dialog-container {
    padding: 16px 24px;
    border-radius: 12px;
    box-shadow: none;
    color: $color-gray-700;
    background-color: $color-white;

    .mat-dialog-title {
        @include heading-4;
        color: $color-gray-900;
        margin-bottom: 8px;
    }

    .mat-dialog-actions {
        margin: 16px 0 0;
        min-height: auto;
        padding: 0;
        gap: 16px;
    }
}

.mat-menu-panel.notifications-menu {
    .mat-menu-content:not(:empty) {
        height: 100%;
    }
}

mat-expansion-panel.mat-expansion-panel:not([class*="mat-elevation-z"]).no-box-shadow,
.mat-expansion-panel:not([class*="mat-elevation-z"]).no-box-shadow,
mat-card.mat-card:not([class*="mat-elevation-z"]).no-box-shadow {
    box-shadow: none;
}

.conversation-sheet {
    padding: 0;
    width: 532px;
    min-width: 532px;
    max-width: 532px;
    font-weight: $typography-weight-normal;

    .mat-dialog-content {
        max-height: 70vh;
    }

    @include for-viewport-size(smartphone, smartphone-wide) {
        max-width: calc(100vw - 32px);
        min-width: calc(100vw - 32px);
    }
}

.mat-menu-panel.notifications-menu {
    max-width: none;
}

.link {
    color: $color-secondary-700;

    &:hover {
        text-decoration: underline;
    }
}

.cdk-overlay-dark-backdrop {
    background: rgba($color-gray-900, 0.6);
}
