@use "libs/shared/styles/style-guide-colors" as *;
@use "libs/shared/styles/style-guide-typography" as *;
@use "libs/shared/styles/mixins" as *;

.dtm-tabs.mat-tab-header,
.dtm-tabs .mat-tab-header {
    box-shadow: inset 0px -1px 0px 0px $color-gray-50;

    .mat-tab-label,
    .mat-tab-link {
        @include heading-6;
        line-height: 20px; //NOTE: specific UX request
        color: $color-gray-300;
        opacity: 1;
        height: auto;
        padding: 6px 16px 4px;
        border-bottom: 2px solid transparent;
        min-width: 0;
    }

    .mat-tab-label:focus-visible {
        @include focus-outline;
        border-radius: 4px;
    }

    .mat-tab-label:not(.mat-tab-label-active):not(.mat-tab-disabled):hover {
        color: $color-gray-500;
    }

    .mat-tab-label-active {
        color: $color-secondary-700;
        border-bottom-color: $color-secondary-700;
    }

    .mat-tab-disabled {
        color: $color-gray-300;
        opacity: 0.5;
    }
}

.mat-tab-body-content {
    padding: var(--mat-tab-body-content-padding);
}

.mat-tab-label {
    flex-grow: var(--mat-tab-label-flex-grow);
}

.mat-tab-body {
    opacity: 0;
    transition-duration: 300ms;

    &.mat-tab-body-active {
        opacity: 1;
    }
}
