@use "style-guide-typography" as *;
@use "style-guide-colors" as *;

@import "./icons/IconFont";

.iconfont {
    font-size: 1.25rem;
    line-height: 1em;
    font-weight: $typography-weight-normal;

    --complex-icon-main-color: #{$color-gray-900};
    --complex-icon-accent-color: #{$color-secondary-500};
}

.icon-complex-altitude-range {
    position: relative;

    &::before {
        position: absolute;
        content: "\e99c"; // arrow-up-down-left
        color: var(--complex-icon-accent-color);
    }

    &::after {
        content: "\e9a0"; // lines-right
        color: var(--complex-icon-main-color);
    }
}

.icon-complex-altitude {
    position: relative;

    &::before {
        position: absolute;
        content: "\e9a7"; // dot-middle
        color: var(--complex-icon-accent-color);
    }

    &::after {
        content: "\e99f"; // lines
        color: var(--complex-icon-main-color);
    }
}

.icon-complex-radius {
    position: relative;

    &::before {
        position: absolute;
        content: "\e951"; // shape-cylinder
        color: var(--complex-icon-main-color);
    }

    &::after {
        content: "\e952"; // radius
        color: var(--complex-icon-accent-color);
    }
}

.icon-complex-height {
    position: relative;

    &::before {
        position: absolute;
        content: "\e99d"; // dot-down
        color: var(--complex-icon-main-color);
    }

    &::after {
        content: "\e999"; // arrow-up-2
        color: var(--complex-icon-accent-color);
    }
}

.icon-complex-top-height {
    position: relative;

    &::before {
        position: absolute;
        content: "\e956"; // line-up
        color: var(--complex-icon-accent-color);
    }

    &::after {
        content: "\e955"; // arrow-up-3
        color: var(--complex-icon-main-color);
    }
}

.icon-complex-bottom-height {
    position: relative;

    &::before {
        position: absolute;
        content: "\e953"; // line-down
        color: var(--complex-icon-accent-color);
    }

    &::after {
        content: "\e954"; // arrow-down-3
        color: var(--complex-icon-main-color);
    }
}

.icon-complex-cylinder-point-left {
    position: relative;
    --complex-icon-accent-color: #{$color-status-success};

    &::before {
        position: absolute;
        content: "\e9a6"; // dot-left-small
        color: var(--complex-icon-accent-color);
    }

    &::after {
        content: "\e9a8"; // shape-cylinder-3d
        color: var(--complex-icon-main-color);
    }
}

.icon-complex-cylinder-point-center {
    position: relative;
    --complex-icon-accent-color: #{$color-primary-900};

    &::before {
        position: absolute;
        content: "\e9a7"; // dot-middle-small
        color: var(--complex-icon-accent-color);
    }

    &::after {
        content: "\e9a8"; // shape-cylinder-3d
        color: var(--complex-icon-main-color);
    }
}

.icon-complex-cylinder-point-right {
    position: relative;
    --complex-icon-accent-color: #{$color-status-success};

    &::before {
        position: absolute;
        content: "\e9a5"; // dot-right-small
        color: var(--complex-icon-accent-color);
    }

    &::after {
        content: "\e9a8"; // shape-cylinder-3d
        color: var(--complex-icon-main-color);
    }
}

.icon-complex-cylinder-top {
    position: relative;
    --complex-icon-accent-color: #{$color-primary-100};

    &::before {
        position: absolute;
        content: "\e9a8"; // shape-cylinder-3d
        color: var(--complex-icon-main-color);
    }

    &::after {
        content: "\e9aa"; // cylinder-top
        color: var(--complex-icon-accent-color);
    }
}

.icon-complex-cylinder-bottom {
    position: relative;
    --complex-icon-accent-color: #{$color-primary-100};

    &::before {
        position: absolute;
        content: "\e9a8"; // shape-cylinder-3d
        color: var(--complex-icon-main-color);
    }

    &::after {
        content: "\e9ab"; // cylinder-down
        color: var(--complex-icon-accent-color);
    }
}

.icon-complex-prism-top {
    position: relative;
    --complex-icon-accent-color: #{$color-primary-100};

    &::before {
        position: absolute;
        content: "\e9ac"; // prism
        color: var(--complex-icon-main-color);
    }

    &::after {
        content: "\e9ad"; // prism-top
        color: var(--complex-icon-accent-color);
    }
}

.icon-complex-prism-bottom {
    position: relative;
    --complex-icon-accent-color: #{$color-primary-100};

    &::before {
        position: absolute;
        content: "\e9ac"; // prism
        color: var(--complex-icon-main-color);
    }

    &::after {
        content: "\e9ae"; // prism-down
        color: var(--complex-icon-accent-color);
    }
}

.icon-complex-waypoint-edit {
    position: relative;
    --complex-icon-accent-color: #{$color-status-success};

    &::before {
        position: absolute;
        content: "\e9a6"; // dot-left-small
        color: var(--complex-icon-accent-color);
    }

    &::after {
        content: "\e9a9"; // segment
        color: var(--complex-icon-main-color);
    }
}

.icon-complex-leaving-area {
    position: relative;
    --complex-icon-accent-color: #{$color-secondary-200};

    &::before {
        position: absolute;
        content: "\e9c2"; // leaving-area-path
        color: var(--complex-icon-main-color);
    }

    &::after {
        content: "\e9c3"; // leaving-area-track
        color: var(--complex-icon-accent-color);
    }
}

.icon-complex-mission-conflict {
    position: relative;
    --complex-icon-accent-color: #{$color-error-400};

    &::before {
        position: absolute;
        content: "\e9c5"; // conflict-arrows
        color: var(--complex-icon-main-color);
    }

    &::after {
        content: "\e9c4"; // conflict-flash
        color: var(--complex-icon-accent-color);
    }
}

.icon-complex-cylinder-fill {
    position: relative;
    --complex-icon-accent-color: #{$color-primary-100};

    &::before {
        position: absolute;
        content: "\e9e1"; // cylinder-line
        color: var(--complex-icon-main-color);
    }

    &::after {
        content: "\e9d1"; // cylinder-fill
        color: var(--complex-icon-accent-color);
    }
}

.icon-complex-cylinder-fill-background {
    position: relative;

    &::before {
        position: absolute;
        content: "\e9e1"; // cylinder-line
        background-image: var(--complex-icon-main-image);
        background-clip: text;
        color: transparent;
    }

    &::after {
        content: "\e9d1"; // cylinder-fill
        background-image: var(--complex-icon-accent-image);
        background-clip: text;
        color: transparent;
    }
}

.icon-complex-cylinder-dash-fill-top-down {
    position: relative;
    --complex-icon-accent-color: #{$color-primary-100};

    &::before {
        position: absolute;
        content: "\e9cf"; // cylinder-dash
        color: var(--complex-icon-main-color);
    }

    &::after {
        content: "\e9d0"; // cylinder-fill-top-down
        color: var(--complex-icon-accent-color);
    }
}

.icon-complex-cylinder-fill-top-down {
    position: relative;

    &::before {
        position: absolute;
        content: "\e9e1"; // cylinder-line
        color: var(--complex-icon-main-color);
    }

    &::after {
        content: "\e9d0"; // cylinder-fill-top-down
        color: var(--complex-icon-accent-color);
    }
}

.icon-complex-team-fill {
    position: relative;
    --complex-icon-main-color: #{$color-gray-500};
    --complex-icon-accent-color: #{$color-gray-500};

    &::before {
        position: absolute;
        content: "\ea09"; // team-fill-complex-1b
        color: var(--complex-icon-main-color);
    }

    &::after {
        content: "\ea0a"; // team-fill
        color: var(--complex-icon-accent-color);
    }
}

.icon-complex-team-fill-less {
    position: relative;
    --complex-icon-main-color: #{$color-gray-500};
    --complex-icon-accent-color: #{$color-gray-200};

    &::before {
        position: absolute;
        content: "\ea09"; // team-fill-complex-1b
        color: var(--complex-icon-main-color);
    }

    &::after {
        content: "\ea0a"; // team-fill
        color: var(--complex-icon-accent-color);
    }
}

.icon-complex-text-block-off {
    position: relative;
    --complex-icon-main-color: #{$color-gray-500};
    --complex-icon-accent-color: #{$color-gray-200};

    &::before {
        position: absolute;
        content: "\ea07"; // text-block-complex-1a
        color: var(--complex-icon-main-color);
    }

    &::after {
        content: "\ea06"; // text-block-complex-1b
        color: var(--complex-icon-accent-color);
    }
}

.icon-complex-text-block {
    position: relative;
    --complex-icon-main-color: #{$color-gray-500};
    --complex-icon-accent-color: #{$color-gray-500};

    &::before {
        position: absolute;
        content: "\ea0b"; // text-block-complex-2a
        color: var(--complex-icon-main-color);
    }

    &::after {
        content: "\ea08"; // text-block-complex-2b
        color: var(--complex-icon-accent-color);
    }
}

.icon-complex-text-block-on {
    position: relative;
    --complex-icon-main-color: #{$color-gray-500};
    --complex-icon-accent-color: #{$color-status-success};

    &::before {
        position: absolute;
        content: "\ea0b"; // text-block-complex-2a
        color: var(--complex-icon-main-color);
    }

    &::after {
        content: "\ea08"; // text-block-complex-2b
        color: var(--complex-icon-accent-color);
    }
}

.icon-complex-mail-notification {
    position: relative;
    --complex-icon-main-color: #{$color-gray-500};
    --complex-icon-accent-color: #{$color-status-error};

    &::before {
        position: absolute;
        content: "\ea2a"; // mail-notification-1
        color: var(--complex-icon-main-color);
    }

    &::after {
        content: "\ea2b"; // mail-notification-2
        color: var(--complex-icon-accent-color);
    }
}

.icon-complex-helicopter {
    position: relative;
    --complex-icon-main-color: #{$color-gray-500};
    --complex-icon-accent-color: #{$color-primary-300};

    &::before {
        position: absolute;
        content: "\e9ee"; // helicopter
        color: var(--complex-icon-main-color);
    }

    &::after {
        content: "\e9b2"; // helicopter-lpr-bg
        color: var(--complex-icon-accent-color);
    }
}
