@use "sass:color";
@use "libs/shared/styles/style-guide-colors" as *;
@use "libs/shared/styles/style-guide-typography" as *;
@use "libs/shared/styles/mixins" as *;

$table-border-radius: 8px;

.dtm-table {
    dtm-ui-loader,
    + dtm-ui-loader {
        --loader-border-radius: 8px;
    }

    &,
    table {
        @include default-box-shadow;
        width: 100%;
        min-height: 100px;
        border-radius: $table-border-radius;
        background: transparent;

        &.no-box-shadow {
            box-shadow: none;
        }
    }

    [mat-header-row] th {
        &:first-child {
            border-top-left-radius: $table-border-radius;
        }

        &:last-child {
            border-top-right-radius: $table-border-radius;
        }
    }

    %sort-arrow {
        position: absolute;
        color: $color-gray-100;
        font-weight: bold;
    }

    %left-sort-arrow {
        content: "\2191";
        @extend %sort-arrow;
    }

    %right-sort-arrow {
        content: "\2193";
        left: -4px;
        @extend %sort-arrow;
    }

    // Override default arrows styling, !important is required to override inline styling
    .mat-sort-header-arrow {
        opacity: 1 !important;
        transform: translateY(-25%) !important;
        font-size: 1rem;
        margin: 0 0 0 4px;

        .mat-sort-header-stem {
            display: none;
        }

        .mat-sort-header-indicator {
            transform: translateY(0) !important;

            // Remove default arrows
            .mat-sort-header-pointer-left,
            .mat-sort-header-pointer-right,
            .mat-sort-header-pointer-middle {
                height: 0;
                width: 0;
                transform: rotate(0) !important;
            }
        }
    }

    [aria-sort="none"] .mat-sort-header-arrow .mat-sort-header-indicator {
        .mat-sort-header-pointer-left::before {
            @extend %left-sort-arrow;
        }

        .mat-sort-header-pointer-right::before {
            @extend %right-sort-arrow;
        }
    }

    [aria-sort="ascending"] .mat-sort-header-arrow .mat-sort-header-indicator {
        .mat-sort-header-pointer-left::before {
            @extend %left-sort-arrow;
            color: $color-secondary-700;
        }

        .mat-sort-header-pointer-right::before {
            @extend %right-sort-arrow;
        }
    }

    [aria-sort="descending"] .mat-sort-header-arrow .mat-sort-header-indicator {
        .mat-sort-header-pointer-left::before {
            @extend %left-sort-arrow;
        }

        .mat-sort-header-pointer-right::before {
            @extend %right-sort-arrow;
            color: $color-secondary-700;
        }
    }

    .pagination-row td {
        border-bottom: none;
        background-color: $color-white;

        &:first-child {
            border-bottom-left-radius: $table-border-radius;
        }

        &:last-child {
            border-bottom-right-radius: $table-border-radius;
        }
    }

    [mat-header-cell] {
        padding: 24px 8px 16px;
        color: $color-gray-300;
        border: none;
    }

    [mat-cell] {
        padding: 8px;
        word-break: break-word;
        color: $color-gray-700;
        border: none;
    }

    [mat-header-cell],
    [mat-cell] {
        @include body-small-short;
        background-color: $color-white;

        &.mat-column-actions {
            width: 60px;
        }

        &.mat-column-expandHandle {
            width: 44px;
            padding-left: 8px;
            color: $color-gray-500;
        }

        &.mat-column-actions,
        &.mat-column-expandHandle {
            .button-icon {
                padding: 8px;

                &:hover {
                    background-color: $color-secondary-100;
                }
            }
        }
    }

    .mat-header-cell:last-of-type,
    .mat-cell:last-of-type,
    .mat-footer-cell:last-of-type {
        padding-right: 8px;
    }

    [mat-row].expanded + .expansion-row .expanded-row-content:not(:empty) {
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .expansion-row .expanded-row-content {
        padding: 0 8px 0 60px;
        transition: padding 300ms;
    }

    .expandable-pointer {
        cursor: pointer;
    }

    [mat-row].expanded td.mat-cell {
        border-bottom-color: $color-secondary-100;
    }

    [mat-row]:hover,
    [mat-row].expanded,
    [mat-row].expanded + tr.expansion-row {
        td {
            background-color: $color-secondary-50;
        }
    }

    [mat-row].expanded + tr.expansion-row td {
        border-bottom-width: 1px;
    }

    [mat-row] + tr.expansion-row td {
        border-top: 1px solid $color-gray-50;
    }

    [mat-row]:not(.expansion-row) + tr:not(.expansion-row) td {
        border-top: 1px solid $color-gray-50;
    }

    [mat-footer-row] dtm-ui-error {
        border-bottom-left-radius: $table-border-radius;
        border-bottom-right-radius: $table-border-radius;
    }

    .hide-footer {
        display: none;
    }

    tr.expansion-row {
        height: 0;

        td {
            border-bottom-width: 0;
            padding: 0;
        }
    }

    tr.pagination-row {
        border-top-width: 1px;
    }

    .sub-cell {
        display: inline-block;

        &:not(:last-child) {
            margin-right: 100px;
            margin-bottom: 16px;
        }

        &.full-width {
            display: block;
            margin-right: 0;
        }
    }

    dtm-ui-popover {
        vertical-align: text-bottom;
    }

    .no-results-row.mat-footer-row td.mat-footer-cell {
        padding: 0;
        border-bottom: none;
    }

    dtm-ui-no-results {
        border-radius: 0 0 $table-border-radius $table-border-radius;
        box-shadow: none;
    }

    .expand-handle {
        display: inline-block;
        transition: transform 0.3s;

        &.expanded {
            transform: rotate(90deg);
        }
    }
}
