@use "libs/shared/styles/style-guide-colors" as *;
@use "libs/shared/styles/style-guide-typography" as *;

// TODO: DTM-5197 - Remove file and library after create custom slider component

ngx-slider.dtm-slider {
    .ngx-slider-bar {
        height: 4px;
        width: calc(100% - 14px);
        margin: 0 8px;
        background: $color-gray-100;
    }

    .ngx-slider-selection,
    .ngx-slider-pointer,
    .ngx-slider-tick.ngx-slider-selected {
        background: $color-gray-500;
    }

    .ngx-slider-pointer {
        width: 16px;
        height: 16px;
        top: auto;
        bottom: -5px;

        &::after {
            display: none;
        }
    }

    .ngx-slider-tick {
        @include heading-6;
        margin-left: 8px;
        width: 0;
        height: 0;

        .ngx-slider-tick-value {
            bottom: 8px;
            top: auto;
        }
    }
}

ngx-slider.dtm-slider-vertical-height {
    &.vertical {
        .ngx-slider-bar {
            width: 8px;
            border-radius: 8px 8px 0 0;
        }

        .ngx-slider-full-bar::after {
            display: block;
            position: absolute;
            bottom: 0;
            left: 10px;
            content: "";
            width: 20px;
            height: 2px;
            background-color: $color-secondary-700;
        }

        .ngx-slider-bar-wrapper {
            margin: 0 0 0 -21px;
        }

        .ngx-slider-ticks-values-under .ngx-slider-tick-value {
            left: 32px;
            right: initial;
            top: -4px;
            font-weight: $typography-weight-normal;
        }

        .ngx-slider-pointer {
            width: 26px;
            height: 26px;
            top: auto;

            //NOTE: It's overriding important in library
            &::after {
                background-color: $color-white !important;
                width: 22px;
                height: 22px;
                left: 2px;
                top: 2px;
                border-radius: 20px;
            }
        }
    }

    .ngx-slider-bubble {
        display: none;
    }

    .ngx-slider-tick {
        @include heading-6;
        margin-left: 8px;
        width: 0;
        height: 0;
    }

    .ngx-slider-selection,
    .ngx-slider-pointer,
    .ngx-slider-tick.ngx-slider-selected {
        background-color: $color-secondary-700;
    }
}
