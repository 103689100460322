@use "libs/shared/styles/style-guide-colors" as *;
@use "libs/shared/styles/style-guide-typography" as *;
@use "libs/shared/styles/mixins" as *;

mat-slide-toggle.mat-mdc-slide-toggle {
    --dtm-slide-toggle-unselected-track-color: #{$color-gray-100};
    --dtm-slide-toggle-unselected-handle-color: #{$color-white};
    --dtm-slide-toggle-selected-track-color: #{$color-secondary-700};
    --dtm-slide-toggle-selected-handle-color: var(--dtm-slide-toggle-unselected-handle-color);
    --dtm-slide-toggle-width: 44px;
    --dtm-slide-toggle-height: 22px;
    --dtm-slide-toggle-handle-padding: 2px;

    height: var(--dtm-slide-toggle-height);
    --mat-slide-toggle-label-text-weight: #{$typography-weight-normal};
    --mat-slide-toggle-label-text-size: inherit;

    .mdc-form-field {
        display: flex;
        align-items: flex-start;
        font-size: inherit;
        gap: var(--dtm-slide-toggle-label-button-gap, 0);
        color: inherit;

        .mdc-label {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: inherit;
        }
    }

    .mdc-switch {
        --mdc-switch-handle-height: calc(var(--dtm-slide-toggle-height) - 2 * var(--dtm-slide-toggle-handle-padding));
        --mdc-switch-handle-width: calc(var(--dtm-slide-toggle-height) - 2 * var(--dtm-slide-toggle-handle-padding));
        --mdc-switch-handle-shape: calc(var(--dtm-slide-toggle-height) / 2);
        --mdc-switch-track-width: var(--dtm-slide-toggle-width);
        --mdc-switch-track-height: var(--dtm-slide-toggle-height);
        --mdc-switch-track-shape: calc(var(--dtm-slide-toggle-height) / 2);
        --mdc-switch-disabled-track-opacity: 0.5;
        margin: var(--dtm-slide-toggle-button-margin, 0 4px);
        border-radius: var(--mdc-switch-track-shape);

        &:focus-within {
            @include focus-outline(2px);
        }

        &--disabled + .mdc-label {
            opacity: 0.5;
        }

        &--selected .mdc-switch__handle-track {
            // NOTE: temporary-var is used, because the calc function notifies about error when used in translateX with var()
            --temporary-var: calc(100% - var(--dtm-slide-toggle-handle-padding));
            transform: translateX(var(--temporary-var));
        }

        &__track {
            --mdc-switch-selected-focus-track-color: var(--dtm-slide-toggle-selected-track-color);
            --mdc-switch-selected-hover-track-color: var(--dtm-slide-toggle-selected-track-color);
            --mdc-switch-selected-pressed-track-color: var(--dtm-slide-toggle-selected-track-color);
            --mdc-switch-selected-track-color: var(--dtm-slide-toggle-selected-track-color);
            --mdc-switch-disabled-selected-track-color: var(--dtm-slide-toggle-selected-track-color);

            --mdc-switch-unselected-focus-track-color: var(--dtm-slide-toggle-unselected-track-color);
            --mdc-switch-unselected-hover-track-color: var(--dtm-slide-toggle-unselected-track-color);
            --mdc-switch-unselected-pressed-track-color: var(--dtm-slide-toggle-unselected-track-color);
            --mdc-switch-unselected-track-color: var(--dtm-slide-toggle-unselected-track-color);
            --mdc-switch-disabled-unselected-track-color: var(--dtm-slide-toggle-unselected-track-color);
        }

        &__handle {
            --mdc-switch-selected-handle-color: var(--dtm-slide-toggle-selected-handle-color);
            --mdc-switch-selected-focus-handle-color: var(--dtm-slide-toggle-selected-handle-color);
            --mdc-switch-selected-hover-handle-color: var(--dtm-slide-toggle-selected-handle-color);
            --mdc-switch-selected-pressed-handle-color: var(--dtm-slide-toggle-selected-handle-color);
            --mdc-switch-disabled-selected-handle-color: var(--dtm-slide-toggle-selected-handle-color);

            --mdc-switch-unselected-handle-color: var(--dtm-slide-toggle-unselected-handle-color);
            --mdc-switch-unselected-focus-handle-color: var(--dtm-slide-toggle-unselected-handle-color);
            --mdc-switch-unselected-hover-handle-color: var(--dtm-slide-toggle-unselected-handle-color);
            --mdc-switch-unselected-pressed-handle-color: var(--dtm-slide-toggle-unselected-handle-color);
            --mdc-switch-disabled-unselected-handle-color: var(--dtm-slide-toggle-unselected-handle-color);

            &-track {
                transform: translateX(var(--dtm-slide-toggle-handle-padding));
            }
        }

        .mdc-switch__shadow,
        .mdc-switch__ripple,
        .mdc-switch__icons {
            opacity: 0;
        }
    }

    &.spread .mdc-form-field {
        justify-content: space-between;

        .mdc-label {
            margin-inline: 0;
        }
    }

    &.allow-wrap {
        height: auto;

        .mdc-form-field .mdc-label {
            white-space: normal;
        }
    }

    &.toggle-bar-center .mdc-form-field {
        align-items: center;
    }
}
