.clickable-image {
    @include for-viewport-size(tablet, desktop, desktop-wide) {
        cursor: pointer;

        &:focus-visible {
            @include focus-outline(var(--clickable-image-outline, 0px));
            border-radius: 4px;
        }
    }
}
