@use "libs/shared/styles/app-theme" as *;

.cesium-widget-credits {
    padding-left: 8px;

    .cesium-credit-logoContainer a[href="https://cesium.com/"]
    {
        display: none;
    }
}

.mat-drawer-backdrop.mat-drawer-shown {
    position: var(--mat-drawer-position);
    z-index: 2000;
}

mat-sidenav-container.mat-drawer-transition .mat-drawer-backdrop {
    transition: var(--mat-drawer-backdrop-transition);
}
