@use "libs/shared/styles/mixins" as *;

.dialog-header {
    display: flex;
    align-items: flex-start;
    gap: 16px;

    [mat-dialog-title] {
        flex: 1 1 auto;
        margin-top: 3px;
    }

    button {
        flex: 0 0 auto;
    }
}

.wide-mobile-dialog {
    @include for-viewport-size(smartphone, smartphone-wide) {
        max-width: calc(100vw - 32px);
        min-width: calc(100vw - 32px);
    }
}
