@use "libs/shared/styles/style-guide-colors" as *;
@use "libs/shared/styles/style-guide-typography" as *;
@use "libs/shared/styles/mixins" as *;
@use "sass:color";

.toast-center-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.toast-top-center {
    top: 70px;
    right: 0;
    width: 100%;
}
.toast-bottom-center {
    bottom: 0;
    right: 0;
    width: 100%;
}
.toast-top-full-width {
    top: 70px;
    right: 0;
    width: 100%;
}
.toast-bottom-full-width {
    bottom: 0;
    right: 0;
    width: 100%;
}
.toast-top-left {
    top: 70px;
    left: 12px;
}
.toast-top-right {
    top: 70px;
    right: 12px;
}
.toast-bottom-right {
    right: 12px;
    bottom: 12px;
}
.toast-bottom-left {
    bottom: 12px;
    left: 12px;
}

/* toast styles */
.toast-title {
    font-weight: bold;
}
.toast-message {
    word-wrap: break-word;
}
.toast-message a {
    color: $color-status-link;

    &:hover {
        text-decoration: underline;
    }
}
.toast-message label {
    color: $color-gray-700;
}

.toast-close-button {
    color: $color-gray-500;
    min-width: 44px;
    height: 44px;
    width: 44px;
    margin: -10px -8px 0 0;

    &:hover {
        color: $color-gray-300;
    }

    & > .icon {
        font-size: 1.4rem;
    }
}

.toast-container {
    pointer-events: none;
    position: fixed;
    // NOTE: toast container must be always over panels and sidebars
    z-index: 9999;
}
.toast-container .ngx-toastr {
    position: relative;
    overflow: hidden;
    pointer-events: auto;
    display: flex;
    align-items: flex-start;
    background: $color-white;
    color: $color-gray-700;
    border-radius: 5px;
    width: 300px;
    margin: 0 0 10px;
    padding: 12px 10px 10px;

    @include toast-box-shadow;

    &.tap-to-dismiss {
        cursor: pointer;

        &.toast-success:hover {
            background: $color-status-success-background-hover;
        }

        &.toast-info:hover {
            background: $color-secondary-100;
        }

        &.toast-warning:hover {
            background: $color-primary-100;
        }

        &.toast-error:hover {
            background: $color-status-error-background-hover;
        }
    }

    &.toast-success,
    &.toast-info,
    &.toast-warning,
    &.toast-error {
        .toast-icon {
            height: 24px;
            min-width: 24px;
            margin-right: 10px;
            background-repeat: no-repeat;

            dtm-ui-icon {
                font-size: 1.5rem;
            }
        }
    }

    &.toast-success {
        background: $color-status-success-background;

        .toast-icon,
        .toast-close-button:hover {
            color: $color-status-success;
        }
    }

    &.toast-info {
        background: $color-secondary-50;

        .toast-icon,
        .toast-close-button:hover {
            color: $color-secondary-600;
        }
    }

    &.toast-warning {
        background: $color-primary-50;

        .toast-icon,
        .toast-close-button:hover {
            color: $color-warning;
        }
    }

    &.toast-error {
        background: $color-status-error-background;

        .toast-icon,
        .toast-close-button:hover {
            color: $color-error-400;
        }
    }

    .text-container {
        width: 100%;
    }
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
    margin-left: auto;
    margin-right: auto;
}
.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
    width: 96%;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (min-width: 500px) {
    .toast-container .ngx-toastr {
        width: 400px;
    }
}
