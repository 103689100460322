@use "libs/shared/styles/style-guide-colors" as *;
@use "libs/shared/styles/style-guide-typography" as *;

.mat-autocomplete-panel {
    background-color: $color-white;
    padding: 8px 0;
    border-radius: 6px;
    box-shadow: 0 6px 10px rgba($color-gray-600, 0.14), 0 1px 18px rgba($color-gray-600, 0.12), 0 3px 5px rgba($color-gray-600, 0.2);

    mat-option.mat-option {
        @include body-small-short;
        color: $color-gray-700;
        padding: 8px 16px;
        height: auto;

        &.mat-option-disabled {
            opacity: 0.5;
        }

        &:not(.mat-option-disabled):hover {
            background-color: $color-secondary-50;
        }

        &.mat-focus-indicator.mat-active {
            background-color: $color-secondary-50;
        }
    }

    &.options-list {
        margin-top: -18px;
    }
}
